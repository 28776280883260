@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  height: 48px;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  min-height: calc(100vh - 6rem);
  justify-content: center;
  align-items: center;
  margin: 3rem;
  display: flex;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  max-width: 400px;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: none;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: none;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147480000;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

/*# sourceMappingURL=index.97ec720b.css.map */
